/* titillium-web-regular - latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/titillium-web-v15-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/titillium-web-v15-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/titillium-web-v15-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/titillium-web-v15-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/titillium-web-v15-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/titillium-web-v15-latin-regular.svg#TitilliumWeb') format('svg'); /* Legacy iOS */
}

/* titillium-web-italic - latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/titillium-web-v15-latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/titillium-web-v15-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/titillium-web-v15-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/titillium-web-v15-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/titillium-web-v15-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/titillium-web-v15-latin-italic.svg#TitilliumWeb') format('svg'); /* Legacy iOS */
}

/* titillium-web-600 - latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/titillium-web-v15-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/titillium-web-v15-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/titillium-web-v15-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/titillium-web-v15-latin-600.woff') format('woff'), /* Modern Browsers */
       url('../fonts/titillium-web-v15-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/titillium-web-v15-latin-600.svg#TitilliumWeb') format('svg'); /* Legacy iOS */
}
